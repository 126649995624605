// -- Questionnaire Sections

export const QUESTIONNAIRE_SECTION_ID_UNIVERSALLY_APPLICABLE_QUESTIONS = 1;

export const QUESTIONNAIRE_SECTION_ID_PARTICIPANTS = 2;

export const QUESTIONNAIRE_SECTION_ID_NON_INSTRUMENTAL_ASSESSMENT_AND_SCREENING = 3;

export const QUESTIONNAIRE_SECTION_ID_VFSS = 4;
export const QUESTIONNAIRE_SECTION_ID_FEES = 5;
export const QUESTIONNAIRE_SECTION_ID_TONGUE_PRESSURE_TOOLS = 6;
export const QUESTIONNAIRE_SECTION_ID_HRPM = 7;
export const QUESTIONNAIRE_SECTION_ID_PEAK_COUGH_FLOW_METER_SPIROMETRY = 8;
export const QUESTIONNAIRE_SECTION_ID_NASAL_CANNULA_AIRFLOW_RESPIRATORY_INDUCTANCE_PLETHYSMOGRAPHY = 9;
export const QUESTIONNAIRE_SECTION_ID_SEMG = 10;
export const QUESTIONNAIRE_SECTION_ID_INDUCED_REFLEXIVE_COUGH_TESTING_PNEUMOTACHOGRAPH = 11;
export const QUESTIONNAIRE_SECTION_ID_ULTRASOUND_ULTRASONOGRAPHY = 12;
export const QUESTIONNAIRE_SECTION_ID_NEUROIMAGING = 13;
export const QUESTIONNAIRE_SECTION_ID_OTHER_INSTRUMENTATION = 14;

export const QUESTIONNAIRE_SECTION_ID_TREATMENT = 15;

export const QUESTIONNAIRE_SECTION_ID_PROM = 16;

// -- Labels

export const LABEL_ID_TRANSPARENCY = 1;
export const LABEL_ID_RIGOR = 2;
